import CustomLink from "./CustomLink";

const CardParticulier = ({ img, alt, title, goTo, from }) => {
  return (
    <CustomLink from={from} to={goTo} className="w-full">
      <div className="h-48 w-[12.25rem] shadow-md rounded-xl flex flex-col lg:w-[17rem] lg:h-60 xx:w-[18rem]">
        <img
          className="w-full h-28 rounded-t-xl object-cover lg:h-40"
          src={img}
          alt={alt}
        />
        <div className="flex flex-col p-2 justify-between space-y-2">
          <div className="w-fit h-fit mt-1 ">{title}</div>
          {goTo !== "/tpe" ? (
            <div className="flex items-center mt-auto cursor-pointer">
              <img src="./assets/svg/chevron-right-red.svg" alt="Chevron" />
              <p className="text-[0.75rem] text-[#8D8F90] ml-2">
                En savoir plus
              </p>
            </div>
          ) : (
            <div className="h-8 w-auto text-white font-bold text-sm rounded bg-main-red mx-1 mb-1 flex justify-center items-center">
              Commander votre TPE
            </div>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default CardParticulier;
