import React from "react";

export default function CardFormule({ data }) {
  console.log(data);
  return (
    <div className="flex flex-col items-center lg:w-[40%] lg:h-fit">
      <p
        className={`hidden lg:block uppercase w-full text-left font-semibold text-[1.4rem]`}
      >
        {data.title}
      </p>
      <div className="lg:flex lg:flex-row xx:w-[27rem]">
        <img
          alt="card"
          src={data.img}
          className="overflow-visible lg:w-[9.6rem] lg:h-[6.9rem] xx:w-[20rem] xx:h-[15rem]"
        />
        <div className="flex flex-col items-center -translate-y-16 lg:-translate-y-0">
          <p className="w-1/2 lg:w-full">
            <span className="text-[2.25rem]">{data.yearPrice}€</span>/mois sur
            base annuelle
          </p>
          <p className="w-1/2 lg:w-full">
            ou {data.monthPrice}€ /mois{" "}
            <span className="text-grey-text">sur base mensuelle</span>
          </p>
        </div>
      </div>
    </div>
  );
}
