import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Ethique() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem] pb-16">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          ÉTHIQUE
        </p>
        <p className="text-left mt-6">
          <strong>B Partner </strong>a choisi de placer l’éthique au centre de
          ses pratiques, avec un objectif clair : protéger ses clients et leurs
          avoirs. Cette valeur fondamentale nous pousse à mettre en œuvre
          plusieurs mesures :{" "}
        </p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>Protection de vos fonds :</strong> 100% de vos fonds sont
            sécurisés, car ils ne sont jamais réinvestis, ni directement ni
            indirectement. 
          </li>
          <li className="">
            <strong>Protection de votre réputation :</strong> Votre argent reste
            disponible à tout moment dans le cadre conformément à la
            réglementation en vigueur. 
          </li>
          <li className="">
            <strong>Protection de votre pouvoir d’achat :</strong> Pour
            faciliter votre gestion tous nos coûts sont clairement et
            précisément documentés dans nos conditions tarifaires. 
          </li>
          <li className="">
            <strong>Protection de vos données :</strong> Vos données ne sont
            jamais vendues ou partagées avec des acteurs commerciaux, sauf avec
            nos partenaires privilégiés.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
