import React, { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import CardFormulePro from "./CardFormulePro";
import Tableau from "./Tableau";
import { Link } from "react-router-dom";

export default function ComparatifPro() {
  const isMobile = useIsMobile();
  const [formule, setFormule] = useState("premium");
  const [activeSection, setActiveSection] = useState(null); // État pour suivre la section active

  const changeFormule = (formule) => {
    setFormule(formule);
  };

  const toggleSection = (index) => {
    // Si l'index est déjà actif, on le désactive, sinon on active la nouvelle section
    setActiveSection(activeSection === index ? null : index);
  };

  const card = {
    prestige: {
      title: "Prestige",
      monthPrice: 46,
      yearPrice: 79,
      img: "./assets/images/prestige-card.png",
    },
    premium: {
      title: "Premium",
      monthPrice: 22,
      yearPrice: 49,
      img: "./assets/images/premium-card.png",
    },
  };

  const comparatif = [
    {
      sectionName: "Comptes et opérations",
      data: [
        { title: "Compte en Euro", premium: "valid", prestige: "valid" },
        {
          title: "Paiement sortant en euros (SEPA)",
          premium: "valid",
          prestige: "valid",
        },
        {
          title: "Paiement instantanné en euros (SEPA)",
          premium: "valid",
          prestige: "valid",
        },
        {
          title: "Paiement programmés et récurrents",
          premium: "valid",
          prestige: "valid",
        },
        {
          title: "Paiement en devise",
          premium: "Plus de 50 devises",
          prestige: "Plus de 50 devises",
        },
      ],
    },
    {
      sectionName: "Services à valeur ajoutée",
      data: [
        {
          title: "Programme fidélité B Partner",
          premium: "valid",
          prestige: "valid",
        },
        { title: "Conciergerie", premium: "valid", prestige: "valid" },
        { title: "Dépannage cash à l’étranger", premium: "2%", prestige: "2%" },
      ],
    },
    {
      sectionName: "Services Prestige",
      data: [
        { title: "Assistant personnel", premium: "Non", prestige: "valid" },
        {
          title: "Conseiller financier personnel",
          premium: "Non",
          prestige: "valid",
        },
        {
          title: "Bilan E-reputation annuelle",
          premium: "Non",
          prestige: "valid",
        },
        { title: "Accès B Partner Club", premium: "Non", prestige: "valid" },
      ],
    },
    {
      sectionName: "Paiement au quotidien",
      data: [
        {
          title: "Paiements en zone euro gratuits",
          premium: "valid",
          prestige: "valid",
        },
        {
          title: "Carte virtuelle gratuite",
          premium: "10 cartes offertes",
          prestige: "Illimité",
        },
        {
          title: "Plafonds de paiement",
          premium: "Gestion en ligne",
          prestige: "Gestion en ligne",
        },
        {
          title: "Carte de débit VISA internationale",
          premium: "PVC (recyclable)",
          prestige: "Métal",
        },
        {
          title: "Paiements hors zone euro",
          premium: "valid",
          prestige: "valid",
        },
      ],
    },
    {
      sectionName: "Retraits et Services",
      data: [
        {
          title: "Cash-back pour tous les paiements",
          premium: "Jusqu'à 1%",
          prestige: "Jusqu'à 1,5%",
        },
        {
          title: "Retraits en zone France",
          premium: "5 gratuits",
          prestige: "10 gratuits",
        },
      ],
    },
    {
      sectionName: "Services pour professionnels",
      data: [
        {
          title: "Terminaux de paiements",
          premium: "Sur demande",
          prestige: "Sur demande",
        },
        {
          title: "Outils d’analyse",
          premium: "fin 2024",
          prestige: "fin 2024",
        },
        {
          title: "Emission de factures",
          premium: "fin 2024",
          prestige: "fin 2024",
        },
        {
          title: "Lien de paiement par SMS ou Email",
          premium: "fin 2024",
          prestige: "fin 2024",
        },
      ],
    },
  ];

  return (
    <div
      id="comparatifPro"
      className="flex flex-col font-poppins px-[1.5rem] overflow-hidden lg:justify-center mt-14 lg:mt-20 xx:px-[3rem]"
    >
      <div
        className="flex flex-col lg:flex-row lg:h-[16rem] lg:items-center "
        id="comparatif-header"
      >
        <div className="lg:w-[25%]">
          <p className="uppercase text-main-red font-bold tracking-widest lg:text-left lg:w-fit">
            NOS OFFRES POUR LES professionnels
          </p>
          <p className="uppercase font-bold text-[1.5rem] mt-2 lg:text-[2.6rem] lg:text-left lg:w-fit">
            DÉCOUVREZ NOS OFFRES
          </p>
          <div
            className={`${
              !isMobile && "hidden"
            } flex flex-row w-full justify-around text-[1.125rem] mt-4`}
          >
            <p
              onClick={() => changeFormule("premium")}
              className={`${
                formule === "premium" &&
                "text-main-red font-semibold border border-main-red rounded"
              } p-[8px]`}
            >
              Premium
            </p>
            <p
              onClick={() => changeFormule("prestige")}
              className={`${
                formule === "prestige" &&
                "text-main-red font-semibold border border-main-red rounded"
              } p-[8px]`}
            >
              Prestige
            </p>
          </div>
        </div>
        {isMobile ? (
          <div>
            <CardFormulePro data={card[formule]} />
            <div className="flex flew-row lg:justify-start justify-center mb-5">
              <Link
                to="https://app.b-partner.com/register"
                target="_blank"
                className="z-10"
              >
                <button className="h-[3rem] w-[8rem] border-2 border-main-red text-main-red font-bold rounded">
                  Souscrire
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-row ml-auto lg:justify-end lg:space-x-12 lg:pr-8 xx:pr-[5rem]">
            <div className="flex flex-col items-center">
              <CardFormulePro data={card["premium"]} />
              <div className="flex flew-row lg:justify-start justify-center">
                <Link
                  to="https://app.b-partner.com/register"
                  target="_blank"
                  className="z-10"
                >
                  <button className="h-[3rem] w-[8rem] border-2 border-main-red text-main-red font-bold rounded">
                    Souscrire
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <CardFormulePro data={card["prestige"]} />
              <div className="flex flew-row lg:justify-start justify-center">
                <Link
                  to="https://app.b-partner.com/register"
                  target="_blank"
                  className="z-10"
                >
                  <button className="h-[3rem] w-[8rem] border-2 border-main-red text-main-red font-bold rounded">
                    Souscrire
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="lg:h-[500px] flex flex-col justify-center">
        {comparatif.map((section, index) => (
          <Tableau
            key={index}
            sectionName={section.sectionName}
            data={section.data}
            formule={formule}
            isActive={activeSection === index} // Vérifie si la section est active
            toggleSection={() => toggleSection(index)} // Fonction pour changer la section active
          />
        ))}
      </div>
    </div>
  );
}
