import { useTpeContext } from "../../contexts/TpeContext";
import { useState } from "react";

const API_URL =
  "https://v2a8vn8tlg.execute-api.eu-west-1.amazonaws.com/tpeSimulator";

export const useTpe = () => {
  const { state, updateState } = useTpeContext();
  const [errorMessage, setErrorMessage] = useState(null);

  const createOrderedRequestBody = (data) => {
    const orderedData = {
      monthly_amount: parseFloat(data.monthly_amount) || 0,
      transaction_count: parseFloat(data.transaction_count) || 0,
      has_printing: data.has_printing === true,
      terminal_cost: parseFloat(data.terminal_cost) || 0,
      rental_cost: parseFloat(data.rental_cost) || 0,
      maintenance_cost: parseFloat(data.maintenance_cost) || 0,
      cost_per_transaction_percent:
        parseFloat(data.cost_per_transaction_percent) || 0,
      cost_per_transaction_cents:
        parseFloat(data.cost_per_transaction_cents) || 0,
    };
    return { data: orderedData };
  };

  const submitForm = async () => {
    try {
      const requestBody = createOrderedRequestBody(state.userInfo);
      const requestBodyString = JSON.stringify(requestBody, [
        "data",
        "monthly_amount",
        "transaction_count",
        "has_printing",
        "terminal_cost",
        "rental_cost",
        "maintenance_cost",
        "cost_per_transaction_percent",
        "cost_per_transaction_cents",
      ]);

      console.log("Request Body (Before POST):", requestBodyString);

      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBodyString,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API Result:", result); // Log pour voir la structure de la réponse

      // Vérification de la réussite de la requête en fonction de la présence de `data_client`
      if (result?.Prestige && result?.Premium) {
        updateState({ apiResult: result });
        updateState({ step: state.step + 1 });
        setErrorMessage(null); // Réinitialiser le message d'erreur en cas de succès
      } else {
        setErrorMessage(
          "Une erreur est survenue lors du traitement. Veuillez vérifier vos données."
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("Erreur de connexion. Veuillez réessayer plus tard.");
    }
  };

  return {
    submitForm,
    errorMessage,
  };
};
