import React, { useState } from "react";
import Retour from "../components/Retour";
import { Link } from "react-router-dom";

export default function ChoixOffre() {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div className="flex flex-row lg:pt-[5.5rem] h-screen">
      <img
        className="hidden lg:flex w-1/2 object-cover"
        alt="man"
        src="./assets/images/choix-offre-img.png"
      />
      <div className="pt-[6rem] px-[1.5rem] font-poppins xx:pl-[8rem] lg:w-[33rem] lg:h-fit lg:flex lg:flex-col lg:justify-start lg:pt-[3rem] xx:w-[50rem] xx:pt-[8rem]">
        <Retour section="sectionOffers" />
        <p className="font-bold text-[1.5rem] text-left mt-[2rem]">
          Pour vous proposer nos offres, nous avons besoin de savoir si vous
          êtes :
        </p>
        <div className="flex flex-col mt-[2rem] bg-white rounded-lg border border-gray-300 ">
          <div
            className={`p-4 cursor-pointer border-b flex items-start ${
              selectedOption === "particulier" ? "bg-gray-100" : ""
            }`}
            onClick={() => setSelectedOption("particulier")}
          >
            <input
              type="radio"
              id="particulier"
              name="offre"
              value="particulier"
              checked={selectedOption === "particulier"}
              onChange={() => setSelectedOption("particulier")}
              className="mr-4 mt-1"
            />
            <div className="text-left">
              <label htmlFor="particulier" className="font-bold text-[1rem]">
                Particulier
              </label>
              <p className="text-gray-500 mt-1 text-left">
                Les offres particuliers sont réservées pour une utilisation
                strictement personnelle, réservée à une personne mineure dont la
                résidence fiscale est la France ou la Belgique.
              </p>
            </div>
          </div>

          <div
            className={`p-4 cursor-pointer flex items-start ${
              selectedOption === "professionnel" ? "bg-gray-100" : ""
            }`}
            onClick={() => setSelectedOption("professionnel")}
          >
            <input
              type="radio"
              id="professionnel"
              name="offre"
              value="professionnel"
              checked={selectedOption === "professionnel"}
              onChange={() => setSelectedOption("professionnel")}
              className="mr-4 mt-1"
            />
            <div className="text-left">
              <label htmlFor="professionnel" className="font-bold text-[1rem]">
                Professionnel
              </label>
              <p className="text-gray-500 mt-1 text-left">
                Pour profiter de nos offres professionnelles, il faut que votre
                société ait un siège social enregistré en France ou en Belgique.
              </p>
            </div>
          </div>
        </div>
        <Link
          to="https://app.b-partner.com/register"
          className="mt-6 w-full bg-main-red text-white py-3 rounded font-bold lg:w-[8rem] "
        >
          <button className="w-full mt-8 lg:mt-0" disabled={!selectedOption}>
            Continuer
          </button>
        </Link>
      </div>
    </div>
  );
}
