import React, { useEffect, useMemo, useState } from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
import DynamicSection from "../components/TPE/DynamicSection";
import { useAppContext } from "../contexts/AppContext";
import HubspotForm from "../components/HubspotForm.tsx";
import SectionTpe from "../components/SectionTpe.tsx";
import useIsMobile from "../hooks/useIsMobile.js";
import SimulateTpeCost from "../components/SimulateTpeCost.js";
import Carrousel from "../components/Carroussel.js";
import { constants } from "../constants/index.ts";
import TpeProduct from "../components/TpeProduct.tsx";
import { useTpeContext } from "../contexts/TpeContext.js";

const PRODUCTS = [
  {
    productName: "N86",
    img: "./assets/images/tpe-pro-bgc.png",
    description:
      "Terminal de paiement robuste à longue autonomie avec impression du ticket",
    mainFeatures: [
      "Impression papier",
      "10 rouleaux thermiques offerts",
      "Livraison offerte",
      "Carte Sim illimitée incluse",
    ],
    technicalSpecs: [
      "Puce, Sans Contact et Piste",
      "1GB RAM + 8GB ROM",
      '5.0" HD',
      "5200 mAh",
    ],
  },
  {
    productName: "N62",
    img: "./assets/images/tpe-flex-bgc.png",
    description:
      "Terminal de paiement léger et compact, idéal pour la mobilité sans impression",
    mainFeatures: [
      "Léger et flexible",
      "Envoie de tickets électroniqes",
      "Livraison offerte",
      "Carte Sim illimitée incluse",
    ],
    technicalSpecs: [
      "Puce, Sans Contact",
      "1GB RAM + 8GB ROM",
      '4.0" HD',
      "2000 mAh",
    ],
  },
  {
    productName: "PAX 8900",
    img: "./assets/images/tpe-blast-bgc.png",
    description:
      "Terminal de paiement Android avec grand écran HD et impression rapide",
    mainFeatures: [
      "Impression papier",
      "10 rouleaux thermiques offerts",
      "Livraison offerte",
      "Carte Sim illimitée incluse",
    ],
    technicalSpecs: [
      "Puce, Sans Contact et Piste",
      "1GB RAM + 8GB ROM",
      '5.5" HD',
      "5150 mAh",
    ],
  },
];
const OFFER = "Prestige Business";

export default function Tpe({ isMobileMenuOpen }) {
  const { updateContext } = useAppContext();
  const { products } = useTpeContext();
  const isMobile = useIsMobile();

  const productSheets = useMemo(() => {
    const newProductSheets = [];
    let filterProducts = [];
    if (products.length) {
      for (const product of PRODUCTS) {
        filterProducts = products.filter(
          (filterProduct) =>
            filterProduct.reference_terminal === product.productName &&
            filterProduct.Product === OFFER
        );
        const newProductSheet = {
          img: product.img,
          product: filterProducts[0]?.["TPE Offer"].replace("*", ""),
          description: product.description,
          mainFeatures: product.mainFeatures,
          technicalSpecs: product.technicalSpecs,
          offers: [
            {
              duration: filterProducts[0]?.["Contract Duration (in months)"],
              price: filterProducts[0]?.["Monthly Rental"],
              transactionCost: filterProducts[0]?.["Commission (%)"] + "*",
              offerPeriod: "2 mois offerts",
            },
            {
              duration: filterProducts[1]?.["Contract Duration (in months)"],
              price: filterProducts[1]?.["Monthly Rental"],
              transactionCost: filterProducts[1]?.["Commission (%)"] + "*",
              offerPeriod: "6 mois offerts",
            },
          ],
        };
        newProductSheets.push(newProductSheet);
      }
    }
    return newProductSheets;
  }, [products]);

  const openForm = (formId) => {
    updateContext("modalOpen", true);
    updateContext(
      "modalElement",
      <HubspotForm portalId="144530178" formId={formId} />
    );
  };

  const [isTpeSimulationModalOpen, setIsTpeSimulationModalOpen] =
    useState(false);

  useEffect(() => {
    if (isTpeSimulationModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isTpeSimulationModalOpen]);

  return (
    <>
      {!isMobileMenuOpen && (
        <SimulateTpeCost onClick={() => setIsTpeSimulationModalOpen(true)} />
      )}
      <div className="pt-[10rem] px-[1.5rem] lg:pt-[13.5rem] xx:px-[6rem] font-poppins mb-[5rem]">
        <Retour page="/" section="tpe" />
        <SectionTpe
          title="terminaux de paiement mobile"
          img={
            <img
              alt="smartphone bpartner"
              src="./assets/images/smartphone-bpartner.png"
              className="mt-4 lg:w-[40.25rem] lg:h-[22.81rem] object-cover xx:w-[50.25rem] xx:h-[28rem] lg:mt-0"
            />
          }
          costs={["0€", "0€", "0,8%"]}
          description={
            <>
              <p className="text-left font-semibold text-[1.125rem] mt-2">
                Acceptez des paiements sur votre téléphone c’est possible !
              </p>
              <p className="text-left">
                Vous pouvez désormais accepter des paiements sur votre téléphone
                Android grâce à votre compte B Partner.
              </p>
              <p className="text-left">
                Notre application intuitive, connectée à votre compte,
                transforme votre téléphone en un véritable terminal de paiement.
                Vous pouvez ainsi accepter les paiements par carte de vos
                clients en toute simplicité et sécurité, tout en suivant chaque
                transaction.
              </p>
            </>
          }
          onClick={() => openForm(constants.hubspot.bPartnerTapFormId)}
          buttonText="Souscrire à B Partner Tap"
          costSubtitle="DES COÛTS ULTRA-COMPETITIFS"
        />

        <div className={`h-10 lg:h-[6rem] flex items-center`}>
          {isMobile && <div className="bg-black w-full rounded h-0.5" />}
        </div>
        <SectionTpe
          title="Nos terminaux de paiement"
          costs={["7,99€", "0€", "0,7%"]}
          description={
            <>
              <p className="text-left">
                Louez nos terminaux de paiement, avec ou sans imprimante et
                carte SIM pour faciliter vos ventes.
              </p>
              <p className="text-left">
                Notre gamme de terminaux B Partner vous accompagne partout afin
                d’accélérer vos ventes. Notre modèle de location « all-in »,{" "}
                <span className="text-black font-semibold">
                  couplé à un coût de transaction très faible
                </span>{" "}
                vous permet de rester compétitif et de protéger vos marges !
              </p>
            </>
          }
          costSubtitle="DES COÛTS ULTRA-COMPETITIFS"
        />
        <Carrousel>
          {productSheets.map((productSheet, index) => (
            <TpeProduct key={index} {...productSheet}>
              <button className="h-[3rem] w-full text-white font-bold rounded bg-main-red ">
                En savoir plus
              </button>
            </TpeProduct>
          ))}
        </Carrousel>

        <button
          className="h-[3rem] w-full lg:w-2/5 mt-10 text-white font-bold rounded bg-main-red "
          onClick={() => setIsTpeSimulationModalOpen(true)}
        >
          Simulez le coût de votre TPE
        </button>
      </div>

      <DynamicSection
        isOpen={isTpeSimulationModalOpen}
        onClose={() => setIsTpeSimulationModalOpen(false)}
      />
      <Footer />
    </>
  );
}
