import React from "react";

interface RadioButtonProps {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const RadioButton = ({ text, isSelected, onClick }: RadioButtonProps) => {
  return (
    <div className="flex space-x-2 items-center" onClick={onClick}>
      <div className="w-6 h-6 border-2 border-main-red rounded-full flex justify-center items-center cursor-pointer">
        <div
          className={`w-4 h-4 rounded-full ${isSelected && "bg-main-red"}`}
        />
      </div>
      <span className="text-sm">{text}</span>
    </div>
  );
};

export default RadioButton;
