import React from "react";
import useIsMobile from "../hooks/useIsMobile";

interface SectionTpeProps {
  title: string;
  costs: [string, string, string];
  description: JSX.Element;
  onClick: () => void;
  buttonText?: string;
  subTitle?: string;
  img: JSX.Element;
  costTitle: string;
  costSubtitle: string;
}

const COSTS_DESCRIPTION = [
  "Coût d'installation",
  "Coût de maintenance",
  "Coût de transaction",
];

const SectionTpe = ({
  title,
  costs,
  description,
  onClick,
  buttonText,
  subTitle,
  img,
  costTitle,
  costSubtitle,
}: SectionTpeProps) => {
  const isMobile = useIsMobile();
  if (costs[0] !== "0€") {
    COSTS_DESCRIPTION[0] = "Coût de location";
  }

  return !isMobile ? (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-around">
        <div className="lg:w-[30.25rem] xx:w-[35rem]">
          {!!subTitle && (
            <p className="uppercase text-main-red text-left font-semibold tracking-widest leading-relaxed mt-8 lg:mt-[6rem]">
              {subTitle}
            </p>
          )}
          <p className="font-bold uppercase text-left mt-4 text-[2.25rem]">
            {title}
          </p>
          {description}
          {!!buttonText && (
            <div className="flex flex-row justify-start my-6">
              <button
                className="h-[3rem] w-full text-white font-bold rounded bg-main-red "
                onClick={onClick}
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>
        {img}
      </div>
      <div className="flex flex-col lg:mt-[4.81rem]">
        <div className="lg:w-fit lg:mx-auto">
          <p className="uppercase font-bold text-left text-[1.5rem] mt-4 lg:text-[2.6rem]">
            {costSubtitle}
          </p>
        </div>
        <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:justify-evenly lg:mt-8 xx:w-[65%] xx:mx-auto mt-8 lg:mt-0">
          {COSTS_DESCRIPTION.map((costDescription, index) => (
            <div className="flex flex-col justify-center items-center w-full h-[14rem] shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">
                {costs[index]}
              </p>
              <p className="text-[1.125rem] font-semibold mt-4">
                {costDescription}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <div>
      {!!subTitle && (
        <p className="uppercase text-main-red text-left font-semibold tracking-widest leading-relaxed mt-8 lg:mt-[6rem]">
          {subTitle}
        </p>
      )}
      <p className="text-[1.5rem] font-bold uppercase text-left mt-4">
        {title}
      </p>
      {img}
      <div className="lg:w-fit lg:mx-auto">
        <p className="uppercase font-bold text-center text-[1.3rem] mt-4 lg:text-[2.6rem]">
          {costSubtitle}
        </p>
      </div>
      <div className="my-6 flex space-x-2">
        {COSTS_DESCRIPTION.map((costDescription, index) => (
          <div className="flex flex-col justify-center items-center w-full h-32 shadow-custom-red">
            <p className="text-main-red text-2xl font-semibold">
              {costs[index]}
            </p>
            <p className="text-base mt-4">{costDescription}</p>
          </div>
        ))}
      </div>
      {!!buttonText && (
        <div className="flex flex-row justify-start my-6">
          <button
            className="h-[3rem] w-full text-white font-bold rounded bg-main-red "
            onClick={onClick}
          >
            {buttonText}
          </button>
        </div>
      )}
      {description}
    </div>
  );
};

export default SectionTpe;
