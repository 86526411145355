import { PdfSimulationPage } from "../components/TPE/PdfSimulation";

export const generateTpePdf = (
  offer: "Prestige" | "Premium",
  tpe: "N86" | "N62" | "SoftPOS" | "PAX 8900",
  duration: "24 mois" | "12 mois",
  apiResult: any,
  tpeTypes: any
): PdfSimulationPage => {
  return {
    tpeName: tpeTypes[tpe].commercial_name,
    duration,
    offer,
    img: tpeTypes[tpe].img,
    isPrinting: apiResult[offer][duration].details[tpe].has_printing,
    terminalCost: apiResult[offer][duration].details[tpe].terminal_cost,
    rentalCost: apiResult[offer][duration].details[tpe].rental_cost,
    maintenanceCost: apiResult[offer][duration].details[tpe].maintenance_cost,
    transactionCostPercent:
      apiResult[offer][duration].details[tpe].cost_per_transaction_percent,
    transactionCostCents:
      apiResult[offer][duration].details[tpe].cost_per_transaction_cents,
  };
};
