const CardBPC = ({ img, alt, title, text }) => {
  return (
    <div className="h-[24rem] w-[13.75rem] rounded-xl lg:rounded-none flex flex-col lg:w-[22rem] lg:h-[25rem] xx:w-[25rem] xx:h-[28rem] shadow-md">
      <img
        className="w-full h-[8.1875rem] rounded-t-xl lg:rounded-none object-cover lg:h-[12rem] xx:h-[15rem] grayscale"
        src={img}
        alt={alt}
      />
      <div className="flex flex-col h-full p-2">
        <div className="w-fit h-fit mt-2 text-left">{title}</div>
        <div className="w-full mt-1 text-[0.8rem] lg:w-full flex-grow lg:text-[1rem] xx:text-[1rem] text-left">
          {text}
        </div>
      </div>
    </div>
  );
};

export default CardBPC;
