import React from "react";
import ReactHubspotForm from "react-hubspot-form";
import { constants } from "../constants/index.ts";

interface HubspotFormProps {
  formId: string;
}

const HubspotForm = ({ formId }: HubspotFormProps) => {
  return (
    <div className="px-8 mt-8">
      <ReactHubspotForm
        region="eu1"
        portalId={constants.hubspot.portalId}
        formId={formId}
      />
    </div>
  );
};

export default HubspotForm;
