import CustomLink from "./CustomLink";

const CardFidelity = ({ img, alt, title, text, goTo, from, subText = "" }) => {
  return (
    <CustomLink from={from} to={goTo} className="h-fit w-fit">
      <div className="h-48 w-[16rem] rounded-xl flex flex-col lg:w-[17rem] lg:h-60 xx:w-[18rem] shadow-md">
        <img
          className="w-full h-28 rounded-t-xl object-cover lg:h-40"
          src={img}
          alt={alt}
        />
        <div className="flex flex-col p-2 justify-between space-y-2">
          <div className="w-fit h-fit mt-1 ">{title}</div>
          <div className="flex items-center mt-auto cursor-pointer">
            <img src="./assets/svg/chevron-right-red.svg" alt="Chevron" />
            <p className="text-[0.75rem] text-[#8D8F90] ml-2">Découvrir</p>
          </div>
        </div>
      </div>
    </CustomLink>
  );
};

export default CardFidelity;
