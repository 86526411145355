import React, { useEffect, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { Link } from "react-router-dom";

export default function SectionBpc({
  title = "B partner club",
  subTitle = "DÉPENSEZ TOUT EN ÉTANT RÉCOMPENSÉ",
  text = "Le B Partner Club vous permet d’obtenir de nombreux privilèges comme des réductions chez nos partenaires ou des avantages B Partner exclusifs.",
  imgName = "section1-home.png",
  altImg = "woman checking her account",
  align = "left",
  id = "section",
  goTo = "#",
  button = true,
  blank = false,
}) {
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 100; // Nombre de caractères maximum avant de tronquer le texte

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  // Fonction pour gérer l'affichage du texte tronqué ou complet
  const getText = () => {
    if (!isMobile) return text; // En mode desktop, affiche tout le texte
    if (isExpanded) return text; // En mode mobile avec texte étendu
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."; // Tronquer le texte
    }
    return text;
  };

  return (
    <div className="flex justify-center" id={id}>
      <div
        className={`flex flex-col justify-start px-[1.5rem] mt-14 lg:mt-20 font-poppins lg:flex-row lg:items-center lg:px-0 ${
          align === "left" ? "lg:pr-[1.5rem]" : "lg:pl-[1.5rem]"
        }`}
      >
        <div
          className={`${
            align === "left" ? "lg:order-2" : "lg:order-1"
          } lg:w-[34.25rem] lg:flex lg:flex-col lg:h-[29.375rem] ${
            align === "left" ? "lg:ml-[3rem]" : "lg:mr-[3rem]"
          } xx:h-[42rem] xx:justify-center flex flex-col justify-start`}
        >
          <p className="uppercase text-main-red font-semibold w-full text-left text-[0.95rem] tracking-[0.2rem]">
            {title}
          </p>
          <p className="uppercase w-full text-left font-bold text-[1.5rem] mt-[1.25rem] lg:text-[2rem] xx:text-[2.5rem]">
            {subTitle}
          </p>
          <p className="text-left text-grey-text">
            {getText()}
            {isMobile && text.length > maxLength && (
              <span
                className="text-main-red cursor-pointer ml-1"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? " Voir moins" : " Voir plus"}
              </span>
            )}
          </p>
          <Link
            to={goTo}
            target={blank && "_blank"}
            className={`w-fit h-fit ${!button && "hidden"}`}
          >
            <button className="mt-[2.5rem] h-[3rem] w-[12rem] border-2 border-main-red text-main-red font-bold rounded">
              Souscrire à une offre
            </button>
          </Link>
        </div>
        <div className={`${align === "left" ? "lg:order-1" : "lg:order-2"}`}>
          <img
            alt={altImg}
            src={"./assets/images/" + imgName}
            className={`grayscale w-full h-auto mt-[3rem] mb-[1.5rem] lg:w-[41.25rem] lg:h-[29.375rem] object-cover lg:mt-0 lg:mb-0 xx:w-[60rem] xx:h-[42rem]`}
          />
        </div>
      </div>
    </div>
  );
}
