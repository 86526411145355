import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Parrainage() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] lg:pb-16 xx:pl-[8rem]">
        <Retour page="/bPartnerFidelite" section="fidelite" />
        <p className="text-[1.5rem] font-bold text-left uppercase mt-8 lg:text-[2.25rem]">
          PARRAINEZ VOS AMIS
        </p>
        <p className="text-left mt-8">
          Invitez vos amis à ouvrir un compte chez B Partner et à l’activer vous
          rapporte des points de fidélité.
        </p>
        <p className="font-bold text-left text-[1.25rem] mt-8">
          Vous gagnez des points de Fidélité
        </p>
        <div className="flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12">
          <div className="w-full border-b text-grey-text flex flex-row space-x-2 items-center justify-center">
            <p className="w-1/2 text-left">Offre du parrain</p>
            <p className="w-1/2 text-left">Nombre de points gagnés</p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Premium</p>
            <p className="w-1/2 text-left">
              <strong>10 ou 20</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Premium business</p>
            <p className="w-1/2 text-left">
              <strong>60</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Prestige</p>
            <p className="w-1/2 text-left">
              <strong>20 ou 40</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Prestige business</p>
            <p className="w-1/2 text-left">
              <strong>100</strong>
            </p>
          </div>
        </div>
        <p className="font-bold text-left text-[1.25rem] mt-8">
          Parrain et Filleul partagent aussi leur Statut !
        </p>
        <p className="text-left mt-2">
          En parrainant vos amis et vos proches vous leur permettez non
          seulement de gagner plus de points de Fidélité, mais aussi de profiter
          d’un bonus de Statut si vous êtes client Gold ou Diamond.
        </p>
        <div className="flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12">
          <div className="w-full border-b text-grey-text flex flex-row space-x-2 items-center justify-center">
            <p className="w-1/2 text-left">Statut du parrain</p>
            <p className="w-1/2 text-left">Statut du parrainé</p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Bronze</p>
            <p className="w-1/2 text-left">
              <strong>Bronze</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Silver</p>
            <p className="w-1/2 text-left">
              <strong>Bronze</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Gold</p>
            <p className="w-1/2 text-left">
              <strong>Silver</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Diamond</p>
            <p className="w-1/2 text-left">
              <strong>Gold</strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
