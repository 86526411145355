import React from "react";
import { useTpeContext } from "../../contexts/TpeContext";
import ProgressBar from "./ProgressBar";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Final from "./Final";
import RetourTpe from "./RetourTpe";

export default function DynamicSection({ isOpen, onClose }) {
  const renderStep = (step) => {
    switch (step) {
      case 0:
        return <Step0 />;
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Final />;
      default:
        return <p>Error</p>;
    }
  };
  const { state, resetState } = useTpeContext();

  const handleClose = () => {
    onClose();
    resetState();
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40 ${
        !isOpen && "hidden"
      }`}
    >
      <div className="overflow-y-scroll bg-white flex flex-col my-8 lg:w-[56.25rem] xx:w-[60rem] lg:mx-auto p-[2rem] rounded-lg shadow-[0px_0px_24px_0px_#3C3C3C] relative max-h-[80%]">
        <img
          alt="close"
          src="./assets/svg/cancel.svg"
          className="w-[1.5rem] h-[1.5rem] absolute cursor-pointer right-7 top-7 z-10"
          onClick={handleClose}
        />
        <div className="flex flex-row items-center relative">
          {state.step > 0 && <RetourTpe />}
          {state.step === 3 || state.step === 2 ? (
            <p className="font-bold lg:text-[1.5rem] mx-auto">
              Resultat de votre simulation
            </p>
          ) : (
            <p className="font-bold lg:text-[1.5rem] mx-auto">
              Simulez le coût de votre TPE
            </p>
          )}
        </div>
        <div className="my-4">{state.step < 3 && <ProgressBar />}</div>
        <div id="content">{renderStep(state.step)}</div>
      </div>
    </div>
  );
}
