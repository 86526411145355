import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
import SelectDevises from "../components/SelectDevises";

export default function Devises() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem]">
        <Retour page="/" section="paiementMultiDevise" />
        <div className="lg:flex flex-row">
          <div className="text-left lg:w-[30.25rem] xx:w-[42rem] xx:flex xx:flex-col xx:justify-center mr-0 lg:mr-5">
            <p className="mt-4 uppercase font-bold text-[1.5rem] xx:text-[2rem] w-fit">
              paiement en devise
            </p>
            <h4 className="text-[1.125rem] font-semibold mt-4 xx:text-[1.5rem]">
              Simplifiez vos Paiements Internationaux
            </h4>
            <p className="text-left">
              Gérez vos paiements en devises en toute autonomie directement
              depuis votre compte B Partner.
            </p>
            <p className="text-left mt-4">
              Que ce soit pour payer vos fournisseurs et partenaires commerciaux
              ou pour soutenir votre famille et vos amis. Envoyez de l’argent
              partout dans le monde tout en maîtrisant les taux de change.
            </p>
            <h4 className="text-[1.125rem] font-semibold mt-4 xx:text-[1.5rem]">
              Un Compte Unique pour tous vos Paiements
            </h4>
            <p className="text-left">
              Accédez à plus de 50 devises, courantes et exotiques, et effectuez
              vos transactions en toute simplicité.{" "}
            </p>
            <p className="text-left mt-4">
              Plus besoin de multiplier les comptes :{" "}
              <span className="font-semibold">
                centralisez votre gestion financière et gagnez du temps
              </span>
              .
            </p>
          </div>
          <img
            src="./assets/images/desktop-section3-home.png"
            alt="old man checking his b partner account"
            className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
          />
        </div>
        <div className="text-left pb-24">
          <p className="mt-8 uppercase font-bold text-[1.5rem] w-fit xx:text-[2rem]">
            Liste des devises
          </p>
          <p className="">Découvrez plus de 50 devises valable dans 170 pays</p>
          <SelectDevises />
        </div>
      </div>
      <Footer />
    </>
  );
}
