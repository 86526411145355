import React from "react";
import { Link } from "react-router-dom";

const SimulateTpeCost = ({
  onClick = () => undefined,
  to = "",
  text = "SIMULEZ LE COÛT DE VOTRE TPE",
}) => {
  return (
    <Link
      to={to}
      className="w-full fixed top-[5.1875rem] lg:top-[5.5rem] z-20 flex flex-row items-center px-[1rem] xx:px-[2rem] bg-main-red h-14 lg:h-16 cursor-pointer"
      onClick={onClick}
    >
      <img
        alt="simulate tpe cost"
        src="./assets/images/icon-go-simulate-tpe-cost.png"
      />
      <span className="ml-3 font-bold text-white lg:text-xl text-base">
        {text}
      </span>
    </Link>
  );
};

export default SimulateTpeCost;
