import React from "react";

const SwitchButton = ({ onClick, value1, value2, selectedValue }) => {
  const values = [value1, value2];
  return (
    <div className="flex item-center bg-white p-1 rounded-full w-[200px] border-2 border-main-red">
      {values.map((value, index) => (
        <button
          key={index}
          onClick={() => onClick(value)}
          className={`flex-1 text-center py-2 font-bold transition-all duration-300 text-xs lg:text-sm ${
            selectedValue === value
              ? "bg-black text-white rounded-full"
              : "text-main-red"
          }`}
        >
          {value}
        </button>
      ))}
    </div>
  );
};

export default SwitchButton;
