import React, { createContext, useContext, useEffect, useState } from "react";

const TpeContext = createContext();

export const useTpeContext = () => useContext(TpeContext);

const INITIAL_STATE = {
  step: 0,
  userInfo: {
    monthly_amount: null, // Anciennement monthlyVolume
    transaction_count: null, // Anciennement monthlyTransactions
    has_printing: null, // Anciennement print
    terminal_cost: null, // Anciennement terminalCost
    rental_cost: null, // Anciennement rentingTerminalCost
    maintenance_cost: null, // Anciennement maintenanceCost
    cost_per_transaction_percent: null, // Anciennement percentageTransactionCost
    cost_per_transaction_cents: null, // Anciennement transactionCost
  },
};

export const TpeProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const res = await fetch(
        "https://6ec1x4if94.execute-api.eu-west-1.amazonaws.com/getTpeData"
      );
      if (res.status === 200) {
        setProducts(await res.json());
      }
    } catch (err) {
      console.error("Error fetching products", err);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const resetState = () => {
    setState(INITIAL_STATE);
  };

  return (
    <TpeContext.Provider value={{ state, updateState, resetState, products }}>
      {children}
    </TpeContext.Provider>
  );
};
