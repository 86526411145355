import React from "react";
import Retour from "../components/Retour";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
export default function DeviseFidelite() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] lg:pb-16 xx:pl-[8rem]">
        <Retour page="/bPartnerFidelite" section="fidelite" />
        <p className="text-[1.5rem] font-bold text-left uppercase mt-8 lg:text-[2.25rem]">
          RÉALISEZ DES PAIEMENTS EN DEVISE
        </p>
        <p className="text-left mt-8">
          Que ce soit pour gâter vos proches à l’étranger ou pour régler vos
          dépenses et vos factures, <strong>B Partner</strong> vous permet de
          réaliser des paiements dans plus de <strong>50 devises</strong> et de
          recevoir des <strong>points de Fidélité</strong> pour chaque euro
          dépensé depuis votre compte.
          <br />
          <br />
          Les points sont acquis sur la base du montant en euros de votre
          paiement en devise. Par exemple, un paiement en devise équivalent à 
          <strong>10 000 €</strong> permettra à un client 
          <strong>Prestige</strong> de recevoir 10 points de Fidélité s’il est
          client <strong>Bronze</strong> ou encore 12 points de Fidélité s’il
          est client <strong>Silver</strong>.
          <br />
          <span className="text-grey-text">
            Plus de 50 devises à découvrir{" "}
            <Link className="underline" to="/devises" target="_blank">
              ici
            </Link>
          </span>
        </p>
        <p className="font-bold text-left text-[1.25rem] mt-8">
          Vous gagnez des points de Fidélité
        </p>
        <div className="flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12">
          <div className="w-full border-b text-grey-text flex flex-row space-x-2 items-center justify-center">
            <p className="w-1/2 text-left">Offre souscrite par le filleul</p>
            <p className="w-1/2 text-left">Paiement en devise</p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Premium</p>
            <p className="w-1/2 text-left">
              <strong>0,10%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Premium business</p>
            <p className="w-1/2 text-left">
              <strong>0,10%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Prestige</p>
            <p className="w-1/2 text-left">
              <strong>0,15%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Prestige business</p>
            <p className="w-1/2 text-left">
              <strong>0,15%</strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
