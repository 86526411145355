import React from "react";
import { useSearchParams } from "react-router-dom";

const Pdf = () => {
  const [searchParams] = useSearchParams();
  const doc = searchParams.get("doc");
  return (
    <iframe
      src={`./assets/docs/${doc}`}
      style={{
        width: "100%",
        height: "100vh",
      }}
    />
  );
};

export default Pdf;
