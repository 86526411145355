import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";

export default function Conciergerie() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] lg:pt-[8rem] xx:pl-[8rem] font-poppins mb-[5rem]">
        <Retour page="/" section="conciergerie" />
        <div
          className="flex flex-col space-y-[2.5rem] lg:mt-[2.5rem]"
          id="conciergerie"
        >
          <div className="">
            <p className="text-main-red uppercase font-poppins tracking-widest w-fit font-semibold mt-4 lg:text-[1.5rem]">
              POUR LES OFFRES PREMIUM
            </p>
            <p className="font-bold text-[1.5rem] w-fit lg:text-[2.25rem]">
              SERVICE CONCIERGERIE
            </p>
            <div className="mt-4 flex flex-col space-y-4 lg:flex-row lg:space-x-[8rem] lg:items-start lg:space-y-0">
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="clock"
                  src="./assets/svg/clock-red.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">9h à 21h</p>
                  <p className="">6 jours sur 7</p>
                  <p className="text-grey-text">(Hors jours feriés)</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit lg:h-full">
                <img
                  alt="phone"
                  src="./assets/svg/phone.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">+33 1 83 81 71 64</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="help & contact"
                  src="./assets/svg/aide-contact.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">Retour dans un délai de 2h</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center">
              <div className="text-left mt-[2.5rem] lg:w-[30.5rem] xx:w-[40rem] mr-0 lg:mr-3">
                <p>
                  La Conciergerie B Partner vous permet d’effectuer les demandes
                  suivantes :
                </p>
                <p className="mt-[2rem]">
                  <strong>• Voyages :</strong> organisation de séjours,
                  recommandations et réservations d’hôtels, de billets de train,
                  d’avion, ...etc.
                </p>
                <p>
                  <strong>• Gastronomie </strong> recommandations et
                  réservations de restaurants, bars, clubs, organisation de
                  services de traiteur, de chef à domicile, de dégustations,
                  ...etc.
                </p>
                <p>
                  <strong>• Quotidien</strong> réservations de taxis, véhicules
                  avec chauffeur, services à la personne, livraison de fleurs,
                  démarches administratives,
                </p>
                <p>
                  <strong>• Culture et bien être :</strong> réservations pour
                  des concerts, spectacles, expositions, sélection d’idées
                  cadeaux, recommandations d’activités sportives et de
                  bien-être.
                </p>
              </div>
              <img
                src="./assets/images/conciergerie.png"
                alt="man crossed arms"
                className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
