import React, { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
// import CardOffer from "./CardOffer";
import { Link } from "react-router-dom";

export default function SectionOffers({
  title = "Nos Offres pour particuliers et professionnels",
  subTitle = "EXPLOREZ NOS OFFRES PRESTIGE ET PREMIUM",
  text = "L’excellence et la personnalisation sont au centre de nos services.<br/><br/>Profitez d’une gestion financière fluide, efficace, simple et transparente.<br/><br/>Chez B Partner, nous comprenons les rythmes irréguliers et les besoins changeants de nos clients d’exception.",
  align = "right",
}) {
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  // Si on est sur mobile et que le texte n'est pas étendu, on le tronque
  const displayedText =
    isMobile && !isExpanded ? `${text.slice(0, 100)}...` : text;

  return (
    <div className="flex mt-14 lg:mt-20" id="sectionOffers">
      <div
        className={`flex flex-col justify-start px-[1.5rem]  font-poppins lg:flex-row lg:items-center lg:px-0 ${
          align === "left" ? "lg:pr-[1.5rem]" : "lg:pl-[1.5rem]"
        }  lg:w-full xx:justify-end xx:pr-[6rem]`}
      >
        <div
          className={`${
            align === "left" ? "lg:order-2" : "lg:order-1"
          } lg:w-[34.25rem] lg:flex lg:flex-col ${
            align === "left" ? "lg:ml-[3rem]" : "lg:mr-[3rem]"
          } xx:justify-center flex flex-col justify-start lg:justify-center xx:mr-28`}
        >
          <p className="uppercase text-main-red font-semibold w-full text-left tracking-[0.2rem]">
            {title}
          </p>
          <p className="uppercase w-full text-left font-bold text-[1.5rem] mt-[1.25rem] lg:text-[2rem] xx:text-[2.5rem]">
            {subTitle}
          </p>
          <p
            className="text-left text-grey-text mt-4"
            dangerouslySetInnerHTML={{ __html: displayedText }}
          />
          {isMobile && (
            <button
              onClick={toggleExpand}
              className="text-grey-text font-semibold mt-2 text-left"
            >
              {isExpanded ? "Lire moins" : "Lire plus"}
            </button>
          )}
          <Link
            to="https://app.b-partner.com/register"
            target="_blank"
            className="mt-[2.5rem] w-[8rem]"
          >
            <button className="h-[3rem] w-[8rem] border-2 border-main-red text-main-red font-bold rounded">
              Souscrire
            </button>
          </Link>
        </div>
        <div
          className={`${
            align === "left" ? "lg:order-1" : "lg:order-2"
          } lg:flex lg:flex-row lg:w-[40.725rem] xx:w-[52rem] mt-4 lg:mt-0`}
        >
          {/* <CardOffer
            img="./assets/images/imgCard-1.png"
            alt="man holding prestige card"
            title="PRESTIGE"
            subtitle="Un compte à la hauteur de votre personnalité"
            bullets={[
              "Accès au B Partner Club",
              "Services Prestige",
              "Assistant Personnel",
              "Service de E-réputation",
            ]}
            margin={true}
          />
          <CardOffer
            img="./assets/images/imgCard-2.png"
            alt="man holding premium card"
            title="PREMIUM"
            subtitle="Un compte qui comprend votre besoin de flexibilité."
            bullets={[
              "Accès au B Partner Club",
              "Paiements en Devise",
              "Programme de Fidélité",
              "Services de Conciergerie",
            ]}
            margin={false}
          /> */}
          <video loop muted autoPlay playsInline controls={false}>
            <source src="./assets/videos/cards_rotation.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}
