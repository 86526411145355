import React, { useState } from "react";
import { useTpeContext } from "../../contexts/TpeContext";
import { useTpe } from "./useTpe";
import { isNumber } from "../../utils/regex.ts";

export default function Step1() {
  const { state, updateState } = useTpeContext();
  const { submitForm, errorMessage } = useTpe();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const normalizedValue = value.replace(",", ".");
    if (!isNumber(normalizedValue) && normalizedValue) {
      return;
    }

    updateState({
      userInfo: {
        ...state.userInfo,
        [name]: normalizedValue,
      },
    });
  };

  const handleNext = async () => {
    if (validateFields()) {
      setIsLoading(true);
      await submitForm();
      setIsLoading(false);
    }
  };

  // Validation du formulaire
  const validateFields = () => {
    const newErrors = {};

    if (
      !state.userInfo.terminal_cost ||
      isNaN(state.userInfo.terminal_cost) ||
      parseFloat(state.userInfo.terminal_cost) < 0
    ) {
      newErrors.terminal_cost =
        "Le coût d'achat doit être un nombre positif ou 0.";
    }

    if (
      !state.userInfo.rental_cost ||
      isNaN(state.userInfo.rental_cost) ||
      parseFloat(state.userInfo.rental_cost) < 0
    ) {
      newErrors.rental_cost =
        "Le coût de location doit être un nombre positif ou 0.";
    }

    if (
      !state.userInfo.maintenance_cost ||
      isNaN(state.userInfo.maintenance_cost) ||
      parseFloat(state.userInfo.maintenance_cost) < 0
    ) {
      newErrors.maintenance_cost =
        "Le coût de maintenance doit être un nombre positif ou 0.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="flex flex-col p-4">
      <form className="grid grid-cols-1 lg:grid-cols-2 gap-4 space-y-4 lg:space-y-0">
        <label className="flex flex-col text-left font-semibold">
          <span>Coût d’achat de votre terminal (en €)</span>
          <input
            type="text"
            name="terminal_cost"
            placeholder="ex: 150"
            value={state.userInfo.terminal_cost ?? ""}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.terminal_cost && (
            <span className="text-red-500 text-[0.875rem] font-normal">
              {errors.terminal_cost}
            </span>
          )}
        </label>

        <label className="flex flex-col text-left font-semibold">
          <span>Coût de la location mensuelle du terminal (en €)</span>
          <input
            type="text"
            name="rental_cost"
            placeholder="ex: 29"
            value={state.userInfo.rental_cost ?? ""}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.rental_cost && (
            <span className="text-red-500 text-[0.875rem] font-normal">
              {errors.rental_cost}
            </span>
          )}
        </label>

        <label className="flex flex-col text-left font-semibold">
          <span>Coût de la maintenance mensuelle (en €)</span>
          <input
            type="text"
            name="maintenance_cost"
            placeholder="ex: 10"
            value={state.userInfo.maintenance_cost ?? ""}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.maintenance_cost && (
            <span className="text-red-500 text-[0.875rem] font-normal">
              {errors.maintenance_cost}
            </span>
          )}
        </label>

        {/* Message d'erreur affiché en cas d'échec de l'API */}
        {errorMessage && (
          <p className="text-red-500 text-[0.875rem] mt-2 lg:col-span-2">
            {errorMessage}
          </p>
        )}

        <button
          type="button"
          onClick={handleNext}
          className="bg-main-red text-white py-2 rounded h-[3rem] font-bold mt-4 lg:col-span-2 lg:w-[14rem] lg:ml-auto flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading && (
            <img
              src="./assets/images/loading-white.gif"
              className="w-7 h-7 mr-2"
            />
          )}
          Suivant
        </button>
      </form>
    </div>
  );
}
