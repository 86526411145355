import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";

const Scroll = () => {
  const { pathname } = useLocation();
  const { state } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (state.modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [state.modalOpen]);
  return null;
};

export default Scroll;
