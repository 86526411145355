import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Proximite() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem] pb-16">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          PROXIMITÉ
        </p>
        <p className="text-left mt-6 underline">
          Qu’est-ce que la proximité chez B Partner ?
        </p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>En cas de difficulté :</strong> Si vous rencontrez un
            problème technique ou si vous ne comprenez pas une opération, un
            conseiller est disponible pour vous aider à résoudre la situation.
          </li>
          <li className="">
            <strong>Pour prendre une décision importante :</strong> Avant de
            prendre une décision financière majeure, il peut être utile de
            consulter un conseiller. 
          </li>
          <li className="">
            <strong>Pour obtenir des conseils personnalisés :</strong> Si vous
            souhaitez optimiser votre gestion de budget ou atteindre un objectif
            financier spécifique, un conseiller peut vous fournir des conseils
            sur mesure.
          </li>
        </ul>

        <p className="text-left mt-6 underline">
          Quels sont les avantages de cette proximité ?
        </p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>Réponses rapides  :</strong> Vos questions trouvent
            rapidement une réponse, ce qui vous permet de gagner du temps.
          </li>
          <li className="">
            <strong>Conseils personnalisés :</strong> Un conseiller peut vous
            apporter des recommandations adaptées à votre situation personnelle
            et à vos objectifs financiers.
          </li>
          <li className="">
            <strong>Accompagnement dans toutes les démarches  :</strong> Que ce
            soit pour ouvrir un compte, souscrire un crédit ou simplement
            obtenir des informations, un conseiller est là pour vous guider.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
